export const SkillsList = [
	[
		'1C',
		'Apex',
		'ASM',
		'Bash',
		'C',
		'C#',
		'C++',
		'Dart',
		'Delphi',
		'Go (Golang)',
		'Groovy',
		'J2EE',
		'Java EE',
		'Java SE',
		'JavaScript',
		'JSP',
		'JSTL',
		'Kotlin',
		'Matlab',
		'Objective-C',
		'Perl',
		'PHP',
		'Python',
		'R',
		'Scala',
		'SOQL & SOSL',
		'Stylus',
		'Swift',
		'Swift 5',
		'T-SQL',
		'TypeScript',
		'Visual Basic',
		'Visual Basic .NET',
		'VisualForce',
		'Wolfram Mathematica',
		'XSD',
		'XSLT',
	],
	[
		'AJAX',
		'ActiveMQ',
		'Algorithms',
		'Annotation processing with kotlin, kotlinpoet',
		'Ansible',
		'Apache HTTP Server',
		'Apache Kafka',
		'Apollo Client',
		'Apple TV',
		'Apple Watch',
		'Artifactory',
		'Bash (Unix shell)',
		'Bash script',
		'Blockchain',
		'Canvas',
		'Clean Architecture',
		'Clean Swift',
		'CSS / CSS3',
		'CSS Modules',
		'Data Protection',
		'Data Structures',
		'DDD',
		'DRY',
		'Eclipse',
		'Eclipse Birt Report Designer',
		'EJB',
		'ElasticSearch',
		'FlyWay',
		'GCD',
		'Glassfish',
		'Graphql',
		'gRPC',
		'HTML5',
		'HTTP/HTTPS',
		'Hyper-V',
		'IBM MQ',
		'Integration Testing',
		'IoT',
		'JSF',
		'Karma',
		'Katalon Studio',
		'KISS',
		'LeakCanary',
		'LESS',
		'LINQ',
		'Lint',
		'LXD',
		'Micro Frontends',
		'Micro Services',
		'Microsoft Intune',
		'Monolite',
		'Multi-threaded/Async programming',
		'MVI',
		'MVP',
		'MVVM',
		'Networking Security Protocols',
		'Node.js',
		'Notification',
		'OAuth',
		'Packer',
		'Pixel Perfect, Adaptive Page Markup',
		'PowerShell',
		'Qlick',
		'RabbitMQ',
		'React Leaflet',
		'Redux ToolKit',
		'Regular Expressions',
		'REST',
		'Rsuite',
		'SAP Commerce platform (Hybris)',
		'SASS',
		'SCSS',
		'SOAP',
		'SSE (Server Sent Events)',
		'SwiftUI',
		'TCP/IP Protocol',
		'Vaadin',
		'WebPack',
		'WebSockets',
	],
	[
		'.NET',
		'.NET Core',
		'.NET WinForms',
		'Alamofire',
		'Allure',
		'Android Framework',
		'Angular',
		'AngularJS',
		'Apache Tapestry',
		'Apache Velocity',
		'Appium',
		'AppsFlyer',
		'ASP.NET CORE',
		'ASP.NET MVC',
		'ASP.NET WebForms',
		'AspectJ',
		'AVFoundation',
		'BanubaSDK',
		'Blazor',
		'Blockchain solutions',
		'Camel Apache',
		'Coil',
		'Combine',
		'CoreGraphics',
		'Coroutines',
		'Cucumber',
		'Data Binding',
		'DataStore',
		'Django',
		'EclipseLInk',
		'EF Core',
		'ExpressJS',
		'Fabric',
		'FastAPI',
		'Firestore',
		'Flask',
		'Flutter',
		'Glide',
		'GWT',
		'Hapi JS',
		'Hibernate',
		'Hibernate Validator',
		'i18next',
		'iOS Foundation',
		'ITSM',
		'Jest',
		'JetPack Compose',
		'JetPack Libraries',
		'JetPack Navigation',
		'JetPack Paging',
		'Junit',
		'Kingfisher',
		'Knockout',
		'Koin',
		'Kotlin Coroutines',
		'Kotlin Flow',
		'Kotlin Serialization',
		'Laravel Framework',
		'Liquibase',
		'LiveData',
		'Log4j/Slf4j',
		'MapKit',
		'MinIO S7',
		'Mockito',
		'NestJS',
		'NET ASP.NET WebAPI',
		'NextJS',
		'Nightwatch',
		'Nimble',
		'Novocaine',
		'NUnit',
		'Nuxt',
		'OpenSSL',
		'OpenStreetMap',
		'Picasso',
		'PlayFramework',
		'Playwright',
		'Project Reactor',
		'Pytest',
		'Quick',
		'React Native',
		'Realm',
		'Requirements management',
		'REST Assured',
		'Retrofit',
		'Retrofit 2',
		'RouteComposer',
		'RxJava',
		'Selenide',
		'Selenium Web Driver',
		'Servlet API',
		'SnapKit',
		'SPM',
		'Spock',
		'Spring AOP',
		'Spring Boot',
		'Spring Cache Abstraction',
		'Spring Cloud',
		'Spring Cloud OpenFeign',
		'Spring Core',
		'Spring DAO',
		'Spring Data',
		'Spring Data Elasticsearch',
		'Spring Data JDBC',
		'Spring Data JPA',
		'Spring Framework',
		'Spring JDBC',
		'Spring MVC',
		'Spring ORM',
		'Spring Security',
		'Spring Security ACL',
		'Spring Transaction',
		'Spring Web',
		'Spring Web MVC',
		'Spring WebFlux',
		'SpriteKit',
		'StoreKit',
		'Symfony Framework',
		'Tailwind CSS',
		'TestNG',
		'Uber Cadence',
		'UIKit',
		'Unit Tests',
		'Vue 2',
		'Vue 3',
		'Webdriver.io',
		'Webflux (Reactor)',
		'WireMock',
		'Wordpress CMS',
		'WPF',
		'XCTest',
		'XUnit',
		'Yii',
	],
	[
		'ANPR',
		'ActiveRecord',
		'Activity',
		'Alembic',
		'Ant Design',
		'Apache Common Validator',
		'Apollo',
		'AssertJ (testing)',
		'Axios',
		'Babel.js',
		'Bootstrap',
		'Brightcove SDK',
		'Casl',
		'ChartJS',
		'chartJs',
		'D3',
		'Dagger',
		'Dagger 2',
		'Dashboard',
		'Data binding',
		'DataBinding',
		'Date-FNS',
		'DevExpress',
		'DnD',
		'Doctrine ORM',
		'Emotion',
		'Enzyme (testing)',
		'Espresso (testing)',
		'Exoplayer',
		'Ext Js',
		'Final-form',
		'Flow',
		'Fluent Validation',
		'Flyway',
		'Formik',
		'GreenDao',
		'Gretty',
		'Guava',
		'Gutenberg',
		'Hilt',
		'I18n',
		'Immutable.js',
		'Itext PDF',
		'Jackson',
		'Jackson API',
		'JasperReports',
		'JavaMail',
		'JAXB',
		'JCL',
		'JMS',
		'JMX',
		'JoCoCo',
		'JPA',
		'JQuery',
		'jQueryUI',
		'JsDoc',
		'JSONP',
		'JSS',
		'JUnit',
		'Junit5',
		'Koa.js',
		'Lodash',
		'Log4j',
		'Lombok',
		'Lottie',
		'Lucene',
		'MapStruct',
		'Material UI',
		'Matplotlib',
		'MaxMind GeoIP',
		'MediatR',
		'Mini Profiler',
		'MobX',
		'Mocha',
		'Model Mapper',
		'Moment.js',
		'MUI',
		'MyBatis',
		'NgRx',
		'Notistack',
		'NumPy',
		'OAUTH2',
		'OCR Tesseract',
		'Odata-query',
		'OpenCV',
		'Paging 3',
		'Pandas',
		'PhpOffice',
		'PHPUnit',
		'Pinia',
		'Plotly',
		'primeng',
		'PrimeReact',
		'PropTypes',
		'Psycopg',
		'Python-oracledb',
		'QueryDSL',
		'Ramda',
		'React',
		'React Hook Form',
		'React Query',
		'React Router',
		'React Testing Library',
		'React transition group',
		'React-beautiful-dnd',
		'React-final-form',
		'React-flow',
		'React-table',
		'Redux',
		'Redux form',
		'Redux Saga',
		'Redux thunk',
		'Redux toolkit',
		'Redux-Observable',
		'RestTemplate',
		'RxJava 2',
		'RxJS',
		'Scikit-Learn',
		'SciPy',
		'Seaborn',
		'Selenium API',
		'ShedLock',
		'SignalR',
		'Sl4j, Logback',
		'Socket.IO',
		'Spring Test',
		'StoryBook',
		'Styled Components',
		'Styleguide',
		'Swinject',
		'Swiper',
		'TestContainers',
		'Tree.js',
		'Valtio',
		'ViewBinding',
		'Vue Router',
		'VueJS',
		'Vuelidate',
		'Vuetify',
		'Vuex',
		'Web sockets',
		'WooCommerce',
		'WorkManager',
		'Yup',
		'Zod',
		'Zustand',
		'Zxing',
	],
	[
		'Cassandra(datastax)',
		'CockroachDB',
		'DB2',
		'Dremio',
		'H2',
		'KeyChain',
		'MariaDB',
		'Microsoft SQL',
		'MongoDB',
		'MS Access',
		'MySQL',
		'Neo4J',
		'NSUD',
		'Oracle DB',
		'PL/SQL',
		'Plist',
		'PostgreSQL',
		'Redis',
		'SQL',
		'SQLite',
		'Sybase',
	],
	[
		'Adaptavist',
		'Apache Airflow',
		'Apache Avro',
		'Apache Cassandra',
		'Apache Flume',
		'Apache Hadoop',
		'Apache HBase',
		'Apache Hive',
		'Apache Hudi',
		'Apache Oozie',
		'Apache ORC',
		'Apache Parquet',
		'Apache Spark',
		'Apache Zookeeper',
		'Ceph',
		'Data Lake',
		'Data Lakehouse',
		'DWH',
		'Elasticsearch',
		'FunRetro',
		'Greenplum',
		'GSON',
		'JSON',
		'LevelDB',
		'Markdown',
		'MDM',
		'Minio',
		'Mock Server',
		'Moshi',
		'NoSql',
		'NuGet',
		'OLAP',
		'OLTP',
		'Oracle SQL Developer',
		'Relational DBs design',
		'Room',
		'Vault',
		'WSDL',
		'XML',
		'XPath',
		'XUL (XML User Interface Language)',
		'YAML',
	],
	[
		'ClearCase',
		'GIT',
		'Mercurial',
		'Microsoft Visual Source Safe',
		'SourceTree',
		'Subversion',
		'TFS',
	],
	[
		'Ant',
		'Apache Maven',
		'Argo CD',
		'Atlassian Bamboo',
		'CircleCI',
		'CocoaPods',
		'Composer',
		'DFD',
		'Fastlane',
		'Gearset',
		'Gradle',
		'Gulp',
		'Kotlin KTS',
		'Kotlin script',
		'MAMP',
		'Maven',
		'Netlify',
		'Openshift',
		'Parcel',
		'Production Deployment',
		'Rancher',
		'Salesforce DX',
		'Sandbox Management',
		'Sbt',
		'TSC',
		'Vite',
		'Webpack',
		'xDebug',
	],
	[
		'Agile (Scrum, Kanban)',
		'API Documentation',
		'AsyncApi',
		'Behavior  Driven Development',
		'BEM',
		'BEM, OOCSS, SMACSS, Atomic CSS',
		'BPMN',
		'BRD',
		'Business logic implementation',
		'C4 notation',
		'CQRS',
		'Data Modeling',
		'Design Patterns',
		'Design Thinking, Human Centered Design, Critical Thinking',
		'DevOps',
		'Documentation standards: ISO',
		'Documentation standards: ГОСТ',
		'Domain Driven Development',
		'ERD',
		'ESB integration',
		'FDD',
		'Feature Sliced Design',
		'FRD',
		'Git Flow',
		'Human Centered Design',
		'Kanban',
		'Lean',
		'MicroFrontends',
		'Microservice Architectures',
		'MQ integration modelling',
		'mTLS',
		'Open API',
		'Requirements and Solution Documentation',
		'Requirements gathering',
		'Requirements prioritization',
		'REST API integration modelling',
		'Risks management',
		'RUP',
		'Scrum',
		'Serverless Architecture',
		'SOAP API integration modelling',
		'Solution Description',
		'task decomposition/formalization',
		'Test Driven Development',
		'UML',
		'Use Cases',
		'User Guides',
		'User Stories',
		'V-Model',
		'Waterfall',
		'XP',
		'YAGNI',
	],
	[
		'ActiveMQ Apache',
		'Adobe After Effects',
		'Adobe AfterEffects',
		'Adobe Experience Cloud',
		'Adobe Illustrator',
		'Adobe Photoshop',
		'Adobe XD',
		'AirDroid',
		'Airtable',
		'Allure report',
		'Allure TestOps',
		'Android Studio',
		'Android Studio Emulator',
		'Ansible Engine/Tower',
		'Ant Migration Tool',
		'Apache JMeter',
		'App center',
		'Asana',
		'ASCIIDocs',
		'Atlassian Confluence',
		'Axure',
		'Azure Pipelines',
		'Balsamiq Mockups',
		'Bamboo CI',
		'BitBucket',
		'Blender',
		'Boomi',
		'Calendly',
		'Camunda',
		'Charles Proxy',
		'Check Point VPN',
		'Chrome DevTools',
		'Chucker',
		'Cisco VPN Client',
		'Computer networking',
		'Conflunce',
		'Congo Composer',
		'Cypress',
		'DataGrip',
		'Datastax (DevCener)',
		'DBeaver',
		'Debugger',
		'Dev Tools',
		'Docker',
		'Docker swarm',
		'Docker-Compose',
		'dotMemory',
		'Draw.io',
		'Eclipse IDE',
		'EFK Stack',
		'ELK',
		'Enterprise Architect',
		'Eslint',
		'Eviews',
		'Excalidraw (visualization)',
		'Fiddler',
		'Figma',
		'FileZilla',
		'Flake8',
		'GanttPro',
		'Genymotion',
		'Ghost Inspector',
		'Git Extensions',
		'GitHub',
		'GitLab',
		'GlobalProtect VPN',
		'Google Ads',
		'Google Analytics',
		'Google API',
		'Google Apps',
		'Google Directions',
		'Google Geocoding',
		'Google Geofence service',
		'Google Geolocation',
		'Google Maps',
		'Google Optimize',
		'Google Places',
		'Google Play Console',
		'Google Pub/Sub',
		'Google Spreadsheets',
		'Google TTS Engine',
		'Grafana',
		'Grunt',
		'Harness',
		'Heroku',
		'Huawei Developers Console',
		'IBM Rational Rose',
		'IBM SPSS Statistics',
		'iMazing',
		'Insomnia',
		'IntelliJ IDEA',
		'iOS Simulators',
		'Jenkins',
		'Jetty',
		'JMSToolBox',
		'JProfiler',
		'Jupyter Notebook',
		'Katalone Studio',
		'Kerio VPN Client',
		'Keycloak',
		'Kibana',
		'Kotest',
		'Kubernetes',
		'LibreOffice',
		'Lighthouse',
		'Logcat',
		'Loki/Prometheus',
		'Lucidchart',
		'Matlab/Octave',
		'Microsoft Excel',
		'Microsoft SQL Server',
		'Microsoft Visual Studio',
		'Miro',
		'MobaXterm',
		'Mobile farm',
		'MongoDB Compass',
		'MonLog',
		'MS Office',
		'MS Teams',
		'MS Visio',
		'MS Visual Studio/Mono',
		'MS Word',
		'MySQL Server 8 (via MySQL Work Brach)',
		'MySQL Workbench',
		'Nexus',
		'Notion',
		'Npm',
		'NVDA',
		'Obsidian',
		'Offset Explorer',
		'Oracle WebLogic Server',
		'Parallels Desktop',
		'PgAdmin',
		'Photoshop',
		'PHP Storm',
		'PhpMyAdmin',
		'PhpStorm',
		'Pict',
		'PlantUML',
		'Platformeco',
		'Portainer',
		'Postman',
		'PowerBI',
		'Principle',
		'Procreate',
		'Prometheus',
		'Protopie',
		'Proxmox',
		'Proxyman',
		'Pug',
		'PuTTY',
		'PyCharm',
		'Pylint',
		'Qlik Sence',
		'QNX Momentix',
		'Rational Plan',
		'RDC',
		'Rider',
		'Robo3T',
		'Robomongo',
		'RStudio',
		'Selenium IDE',
		'Selenoid',
		'SFDX CLI',
		'SharePoint',
		'Sketch',
		'Sniffers',
		'SoapUI',
		'Sonarqube',
		'Spring Boot Admin',
		'SQL Developer',
		'SQL Server Profiler',
		'Statistica',
		'Sublime',
		'Swagger',
		'Swiftgen',
		'Tableau',
		'TalkBack',
		'TeamCity',
		'TestComplete',
		'TestFlight',
		'TestIt',
		'TIBCO Jasper soft Studio',
		'Tilda',
		'Tomcat Apache',
		'TortoiseSVN',
		'UXPressia',
		'Veeam Backup and Replication',
		'Vim',
		'Visual Paradigm',
		'Visual Studio',
		'VisualVM',
		'VM',
		'VM VirtualBox',
		'VMWare',
		'VMwareHyper-V',
		'VoiceOver',
		'VSC',
		'VSCode',
		'VSCodium',
		'WAVE',
		'WebStorm',
		'Windows Backup and Restore',
		'Winhex',
		'WinSCP',
		'Workbench',
		'XCode',
		'Xray',
		'Yarn',
		'Zabbix',
		'Zeplin',
		'Яндекс.Метрики',
	],
	[
		'Android',
		'Blackberry',
		'Cisco IOS',
		'iOS',
		'Linux',
		'Mac OS',
		'Microsoft Windows',
		'UNIX-system',
	],
	[
		'Atlassian JIRA',
		'AutomatedQA AQdevTeam',
		'ClickUp',
		'Fluentd',
		'Graylog',
		'Helix',
		'HP ALM',
		'Pivotal',
		'Redmine',
		'Shortcut',
		'Squash',
		'Taiga',
		'TestLodge',
		'TRELLO',
		'Wiki',
		'Yandex Tracker',
		'YouTrack',
	],
	[
		'ADO.NET',
		'Apache Cayenne',
		'CoreData',
		'Dapper',
		'EclipseLink',
		'Entity Framework',
		'eXpress Persistent Objects (XPO)',
		'Hibernate ORM',
		'JOOQ',
		'Linq2DB',
		'MikroORM',
		'Mongoose',
		'Sequelize',
	],
	[
		'A/B Testing',
		'Acceptance and Evaluation Criteria Definition',
		'Accessibility Testing',
		'API Testing',
		'Automation testing',
		'Backend Testing',
		'Bug registration',
		'Build Verification',
		'Compatibility Testing',
		'Corridor Testing',
		'Creating a test plan / report of test results',
		'Creating how-to guides',
		'Design testing',
		'E2E Testing',
		'Exploratory testing',
		'Failover and Recovery Testing',
		'Functional testing',
		'Game testing',
		'GUI testing',
		'Installation testing',
		'Integration testing',
		'Interoperability testing',
		'Load testing',
		'Localization testing',
		'Mobile testing',
		'Non-functional testing',
		'Performance testing',
		'QA Documentation',
		'Regression Testing',
		'Requirements testing',
		'Re-testing',
		'Salesforce testing',
		'Security testing',
		'System testing',
		'Test Cases',
		'Test design techniques',
		'Testing documentation preparation',
		'UAT Testing',
		'UI/UX testing',
		'Unit testing',
		'Usability Testing',
		'XCTests',
	],
	['Qase', 'qTest', 'Test IT', 'TestLink', 'TestRail', 'Zephyr', 'Zephyr Squad'],
	[
		'Analysis and Usability Testing',
		'Analysis of Business Problem and Business Goals',
		'CJM: Customer Journey Mapping',
		'Competitive Research',
		'Design systems',
		'Email template design',
		'Guidelines and Using of UI Library: Material Design (Android), Human Centered Interfaces (iOs), SLDS (Salesforces)',
		'High Fidelity Interactive Prototyping',
		'Information Architecture',
		'iOS human interface guidelines',
		'Low Fidelity Prototyping. Wireframes: Paper Prototype, Sketches, Interactive Prototyping',
		'Mindmaps',
		'Patterns: Navigation, Correct using of UI-elements, Mobile Patterns',
		'Personas',
		'Research',
		'Scenarios: JTBD (Job Stories) User Stories Impact Mapping Use Cases Text Scenarios',
		'Smart Watch',
		'Typography, Iconographic, Color Theory, Gestalt Theory Work with Design System',
		'UI prototyping',
		'UI/UX(Xib, Storyboards, layout with code)',
		'Usability',
		'User Experience design',
		'User Interface design',
		'User interface design',
		'User Research',
		'UX Audit by Heuristic',
		'UX Prototyping',
		'UX-Strategy. Strategy Vision: Product Strategy, Business Model Canvas, Lean Canvas, Features Canvas, Value Proposition Canvas',
		'UX-writing: Registry of Content, Terms UX-copywriting',
		'Web Design',
		'Wireframing',
	],
	[
		'ADB',
		'Android Profiler',
		'Android SDK',
		'Animations',
		'CameraX',
		'Custom view',
		'GPS',
		'NFC',
		'RecyclerView',
	],
	[
		'Amazon Web Services (AWS)',
		'Firebase',
		'Firebase Cloud Message',
		'Firebase Crashlytics',
		'Firebase Realtime Database',
		'Firebase Storage',
		'GCP/Cloud Computing Platforms',
		'Google Cloud Platform',
		'Google Cloud Services',
		'IBM Cloud',
		'Microsoft Azure',
		'S3',
		'Yandex Cloud',
	],
	['Experience Cloud', 'Marketing Cloud', 'Sales Cloud', 'Service Cloud'],
	[
		'Customizing Navigation, Buttons, and Links, Global Actions',
		'Data Management (Data Loader, Data Import Wizard)',
		'Duplicate Management',
		'Experience Cloud Sites',
		'External Objects',
		'Field Service Lightning',
		'Force.com Sites',
		'Marketing cloud automation tools (Journey builder, Automation Studio, Email Studio, etc.)',
		'Omni Channel',
		'Process Automation',
		'Salesforce Classic',
		'Salesforce Developer Console',
		'Salesforce Inspector',
		'Salesforce Lightning',
		'Salesforce UI Customization',
		'Salesforce Workbench',
		'Service Console',
		'Sharing Model and Data Security',
		'Terraform',
		'Translations',
		'User Management',
	],
	[
		'Apex Integration Services',
		'Apex Remote objects',
		'Apex REST',
		'Apex Security and Sharing',
		'Apex Triggers',
		'Apex Unit Test',
		'Asynchronous Apex (Scheduled Execution of Apex, Apex Batch Processing, Future methods, Queueable)',
		'Debugging Apex',
		'Lightning Experience (Aura Components, Lightning Web Components, Lightning Design System, Apps)',
		'Open Source LWC',
		'Platform Events',
		'Salesforce API',
		'Visualforce',
	],
	[
		'“1C Bitrix” CMS',
		'Accessibility',
		'Adaptability',
		'Adobe Experience Manager',
		'Akka Actors',
		'AmChart',
		'Amplitude',
		'AMQP',
		'Apache ActiveMQ',
		'Apache HTTP // Application and Web Servers',
		'Apache Solr',
		'Apache Tomcat',
		'Application architecture design',
		'bash',
		'Business correspondence',
		'Cisco AnyConnect VPN',
		'Cisco Firepower, ASA',
		'Cloud Solutions',
		'Code Review',
		'Codefresh',
		'Collaboration',
		'Creation of technical solutions',
		'Cron Utils',
		'Crone',
		'Devops practices',
		'Domain-driven design',
		'Ejs',
		'Feature-Sliced Design',
		'Financial accountability',
		'FortiGate',
		'FullStory',
		'Gatling',
		'GeoJSON',
		'Graphic Design',
		'Helm',
		'Icon design',
		'IIS',
		'ISO8583',
		'JBOSS',
		'Jetbrains Rider',
		'JPQL',
		'JWT',
		'Kestrel',
		'LaTex',
		'Logback',
		'Market Analysis',
		'Math. Statistics',
		'Mathanalysis',
		'MLKit',
		'MVC',
		'MVVM, MVI, MVP',
		'Network configuration',
		'Network security',
		'New Relic',
		'Nextcloud',
		'Nginx',
		'nginx',
		'NSwag Studio',
		'Nunjucks',
		'OpenStreetMaps',
		'ORM Diagram (Object-Relational Mapping)',
		'Outlook',
		'Presentation skills',
		'Probability theory',
		'Product Analysis',
		'Product/feature presentation',
		'"Project management	"',
		'Prometheus // Monitoring',
		'Quartz',
		'Requirement gathering',
		'Requirements Management',
		'SIEBEL',
		'Slack',
		'SQL Server Management Studio',
		'SSE',
		'SSH',
		'Systems Analysis',
		'Systems Architecture',
		'Task decomposition',
		'TCP',
		'Technical interviewing',
		'Thymeleaf',
		'UDP',
		'Vagrant',
		'vault, gpg, smtp/smpp',
		'Vector illustration',
		'VIPER',
		'Visual Communication',
		'Visual Design',
		'VMWare // Hypervisors and Virtualization',
		'WebLogic',
		'WebRTC',
		'Windows Server (AD, DNS, DHCP)',
		'Zabbix // Monitoring',
	],
];
