import dayjs from 'dayjs';
import { CvModel } from '@entities/cv';

export interface ItemType<T> {
	id: string;
	item: T;
}

export type TSkillsForm = ItemType<{ name: string; level: string }>[];
export type TPastProjectExpForm = { periodStart: any; periodEnd: any; dateOnGoing: boolean } & Omit<
	CvModel.TPastProjectExp,
	'period' | '_id'
>;
export interface ICvForm {
	nda: boolean;
	filename: string;
	firstName: string;
	lastName: string;
	middleName: string;
	birthday: string;
	position: string | null;
	primarySkill?: string;
	totalYearsOfExp?: string;
	city: string;
	citizenship: string;
	techStack: string;
	introduction?: string;

	education: ItemType<CvModel.TEducation>[];
	certifications: ItemType<CvModel.TCertification>[];
	language: ItemType<CvModel.TLanguages>[];
	skills: ItemType<TSkillsForm>[];
	pastProjects: ItemType<TPastProjectExpForm>[];
}

export class CvFormDto implements ICvForm {
	nda;
	filename;
	introduction;
	firstName;
	lastName;
	middleName;
	birthday;
	position;
	primarySkill;
	city;
	citizenship;
	techStack;
	totalYearsOfExp;

	education;
	certifications;
	language;
	skills;
	pastProjects;

	constructor(
		language: 'ru' | 'en' = 'en',
		data?: CvModel.TCvResponse['userDataId'],
		filename?: CvModel.TCvResponse['userDataId']['filename'],
	) {
		this.nda = false;
		this.filename = !data ? 'Senla Profile [Guest_Mode]' : filename || 'Senla Profile [Guest_Mode]';
		this.firstName = data?.basicInfo?.fullName.split(' ')[0] || '';
		this.lastName = data?.basicInfo?.fullName.split(' ')[1] || '';
		this.middleName = data?.basicInfo.middleName || '';
		this.birthday = data?.basicInfo.birthday || '';
		this.position = data ? data?.basicInfo?.position : null;
		this.primarySkill = data?.basicInfo?.primarySkill || '';
		this.totalYearsOfExp = data?.basicInfo?.totalYearsOfExp || '';
		this.city = data?.basicInfo.city || '';
		this.citizenship = data?.basicInfo.citizenship || '';
		this.techStack = data?.basicInfo.techStack || '';
		this.introduction = data?.introduction || '';

		this.education = !data
			? []
			: data?.education?.map((element) => ({
					id: element._id as string,
					item: element,
			  })) || [];

		this.language = !data
			? []
			: data?.languages?.map((element) => ({
					id: element._id as string,
					item: element,
			  })) || [];

		this.skills = !data ? [] : SkillsDto(data?.skills);

		this.certifications =
			data && data.certifications
				? data?.certifications?.map((element) => ({
						id: element._id as string,
						item: element,
				  })) || []
				: [];

		this.pastProjects = !data ? [] : PastProjectsDto(data?.pastProjectExp, language);
	}

	static sendCv(data: ICvForm, language: 'ru' | 'en'): CvModel.TCvResponse['userDataId'] {
		return {
			filename: data.filename,
			introduction: data.introduction,
			basicInfo: {
				fullName: `${data.firstName} ${data.lastName}`,
				middleName: data.middleName,
				birthday: data.birthday,
				primarySkill: data.primarySkill,
				totalYearsOfExp: data.totalYearsOfExp,
				position: data.position ? data.position : '',
				city: data.city,
				citizenship: data.citizenship,
				techStack: data.techStack,
			},
			education: data.education.map(({ item: { _id, ...rest } }) => ({ ...rest })),
			certifications: data.certifications.map(({ item }) => ({ name: item.name })),
			pastProjectExp: PastProjectsDtoSend(data.pastProjects, language),
			languages: data.language.map((el) => ({ name: el.item.name, level: el.item.level })),
			skills: SkillsDtoForSend(data.skills),
		} as CvModel.TCvResponse['userDataId'];
	}
}

function SkillsDto(skills: CvModel.TCvResponse['userDataId']['skills']): ItemType<TSkillsForm>[] {
	const res: ItemType<TSkillsForm>[] = [];
	skills?.forEach((skill) => {
		const skillInArray = res.findIndex((item) => item.id === skill.category);
		if (skillInArray < 0) {
			res.push({
				id: skill.category,
				item: [{ id: skill._id as string, item: { name: skill.name, level: skill.level } }],
			});
		} else {
			const childrenSkills = res[skillInArray].item;
			res[skillInArray].item = [
				...childrenSkills,
				{ id: skill._id as string, item: { level: skill.level, name: skill.name } },
			];
		}
	});
	return res;
}
function SkillsDtoForSend(skills: ICvForm['skills']): CvModel.TSkills[] {
	const newSkillsList: CvModel.TSkills[] = [];
	skills.forEach((skillGroup) => {
		skillGroup.item.forEach(({ item: { name, level } }) => {
			newSkillsList.push({ name, level, category: skillGroup.id });
		});
	});
	return newSkillsList;
}

function PastProjectsDto(
	projects: CvModel.TCvResponse['userDataId']['pastProjectExp'],
	language: 'en' | 'ru',
): ItemType<TPastProjectExpForm>[] {
	const getPeriod = (
		period: CvModel.TPastProjectExp['period'],
	): { periodStart: string; periodEnd: string; dateOnGoing: boolean } => {
		const periodForForm = { dateOnGoing: false, periodEnd: '', periodStart: '' };
		const periodArr = period.split(' - ');

		if (periodArr[0]) {
			const [mont, year] = periodArr[0].split('.');
			if (mont && year) {
				periodForForm.periodStart = dayjs()
					.set('month', +mont - 1)
					.set('year', +year)
					.format('MM.YYYY');
			}
		}
		if (periodArr[1]) {
			if (language === 'ru' && periodArr[1] === 'по настоящее время') {
				periodForForm.dateOnGoing = true;
			} else if (periodArr[1] === 'ongoing') periodForForm.dateOnGoing = true;
			else {
				const [mont, year] = periodArr[1].split('.');
				if (mont && year) {
					periodForForm.periodEnd = dayjs()
						.set('month', +mont - 1)
						.set('year', +year)
						.format('MM.YYYY');
				}
			}
		}
		return periodForForm;
	};
	return (
		projects?.map(({ _id, period, ...rest }) => {
			const periodForForm = getPeriod(period);
			return {
				id: _id as string,
				item: { ...rest, ...periodForForm },
			};
		}) || []
	);
}
function PastProjectsDtoSend(
	projects: ItemType<TPastProjectExpForm>[],
	language: 'en' | 'ru',
): CvModel.TCvResponse['userDataId']['pastProjectExp'] {
	const ongoing = language === 'en' ? 'ongoing' : 'по настоящее время';
	return projects.map(({ item: { periodStart, periodEnd, dateOnGoing, ...rest } }) => ({
		...rest,
		period: `${periodStart} - ${dateOnGoing ? ongoing : periodEnd}`,
	}));
}
